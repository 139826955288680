import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
// components
import Root from './Root';
// import PrivateRoute from './PrivateRoute';
// import Privacy from 'pages/other/Policy/Privacy';

// lazy load all the views
// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
// const SignUp = React.lazy(() => import('../pages/auth/SignUp'));
// const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
// const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
// const Logout = React.lazy(() => import('../pages/auth/Logout'));

// home
const Home = React.lazy(() => import('../pages/Home'));
const Features = React.lazy(() => import('../pages/other/Features'));

// landings
const App = React.lazy(() => import('../pages/Home'));
const Saas = React.lazy(() => import('../pages/Home'));
const Saas2 = React.lazy(() => import('../pages/Home'));
const Startup = React.lazy(() => import('../pages/Home'));
const Software = React.lazy(() => import('../pages/Home'));

const Agency = React.lazy(() => import('../pages/Home'));
const Coworking = React.lazy(() => import('../pages/Home'));
const Crypto = React.lazy(() => import('../pages/Home'));
const Marketing = React.lazy(() => import('../pages/Home'));
const Portfolio = React.lazy(() => import('../pages/Home'));

// pages
// const Dashboard = React.lazy(() => import('../pages/other/account/Dashboard'));
// const Settings = React.lazy(() => import('../pages/other/account/Settings'));
// const AddPost = React.lazy(() => import('../pages/other/account/AddPost'));

const Blog = React.lazy(() => import('../pages/other/Blog'));
const Blogs = React.lazy(() => import('../pages/other/TestUsers/BlogPost1')); // import('../pages/other/Blog/testUsers');
const BlogPost = React.lazy(() => import('../pages/other/Post'));
const NewPost = React.lazy(() => import('../pages/other/Post'));

const Investment = React.lazy(() => import('../pages/other/Investments'));
const InvestmentPost = React.lazy(() => import('../pages/other/BlogPost'));

const About = React.lazy(() => import('../pages/other/About'));
const Contact = React.lazy(() => import('../pages/other/Contact'));
const Policy = React.lazy(() => import('../pages/other/Policy'));
const Career = React.lazy(() => import('../pages/Home'));
const Pricing = React.lazy(() => import('../pages/other/Pricing'));

const PortfolioGrid = React.lazy(() => import('../pages/Home'));
const PortfolioMasonry = React.lazy(() => import('../pages/Home'));
const PortfolioItem = React.lazy(() => import('../pages/Home'));

const HelpDesk = React.lazy(() => import('../pages/other/HelpDesk'));

// docs
const Introduction = React.lazy(() => import('../pages/Home'));
const QuickStart = React.lazy(() => import('../pages/Home'));
const Customization = React.lazy(() => import('../pages/Home'));
const Routing = React.lazy(() => import('../pages/Home'));
const CodeSpliting = React.lazy(() => import('../pages/Home'));
const ChangeLog = React.lazy(() => import('../pages/Home'));

const Colors = React.lazy(() => import('../pages/Home'));
const Typography = React.lazy(() => import('../pages/Home'));

const Bootstrap = React.lazy(() => import('../pages/Home'));
const Custom = React.lazy(() => import('../pages/Home'));
const Plugins = React.lazy(() => import('../pages/Home'));

const Navbars = React.lazy(() => import('../pages/Home'));
const Heros = React.lazy(() => import('../pages/Home'));

const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

// const LazyWrapper: React.FC<MyProps> = (props) => {
//     return <Blogs {...props} />
//   }

const AllRoutes = () => {
    return useRoutes([
        {
            // root route
            path: '/',
            element: <Root />,
        },
        {
            // public routes
            path: '/',
            children: [
                {
                    path: 'auth',
                    children: [
                        { path: 'login', element: <LoadComponent component={Login} /> },
                //        { path: 'signup', element: <LoadComponent component={SignUp} /> },
                //         { path: 'forget-password', element: <LoadComponent component={ForgetPassword} /> },
                //         { path: 'confirm', element: <LoadComponent component={Confirm} /> },
                //         { path: 'logout', element: <LoadComponent component={Logout} /> },
                    ],
                },
                {
                    path: 'login',
                    element: <LoadComponent component={Login} />,
                },
                {
                    path: 'docs',
                    children: [
                        { path: 'introduction', element: <LoadComponent component={Introduction} /> },
                        { path: 'quick-start', element: <LoadComponent component={QuickStart} /> },
                        { path: 'customization', element: <LoadComponent component={Customization} /> },
                        { path: 'routing', element: <LoadComponent component={Routing} /> },
                        { path: 'code-spliting', element: <LoadComponent component={CodeSpliting} /> },
                        { path: 'change-log', element: <LoadComponent component={ChangeLog} /> },

                        { path: 'colors', element: <LoadComponent component={Colors} /> },
                        { path: 'typography', element: <LoadComponent component={Typography} /> },
                        { path: 'bootstrap', element: <LoadComponent component={Bootstrap} /> },
                        { path: 'custom', element: <LoadComponent component={Custom} /> },
                        { path: 'plugins', element: <LoadComponent component={Plugins} /> },
                        { path: 'navbars', element: <LoadComponent component={Navbars} /> },
                        { path: 'heros', element: <LoadComponent component={Heros} /> },
                    ],
                },
                {
                    path: 'home',
                    element: <LoadComponent component={Home} />,
                },
                {
                    path: 'features',
                    element: <LoadComponent component={Features} />,
                },
                { path: 'about', element: <LoadComponent component={About} /> },
                { path: 'contact', element: <LoadComponent component={Contact} /> },
                {
                    path: 'blog',
                    children: [
                        { path: '', element: <LoadComponent component={Blog} /> },
                        { path: ':id', element: <LoadComponent component={NewPost} /> },
                    ],
                },
                { 
                    path: 'blogs', 
                    children: [
                        { path: '', element: <LoadComponent component={Blogs} /> },
                    ],
                },
                { path: 'pricing', element: <LoadComponent component={Pricing} /> },
                {
                    path: 'landing',
                    children: [
                        { path: 'app', element: <LoadComponent component={App} /> },
                        { path: 'saas', element: <LoadComponent component={Saas} /> },
                        { path: 'saas2', element: <LoadComponent component={Saas2} /> },
                        { path: 'startup', element: <LoadComponent component={Startup} /> },
                        { path: 'software', element: <LoadComponent component={Software} /> },

                        { path: 'agency', element: <LoadComponent component={Agency} /> },
                        { path: 'coworking', element: <LoadComponent component={Coworking} /> },
                        { path: 'crypto', element: <LoadComponent component={Crypto} /> },
                        { path: 'marketing', element: <LoadComponent component={Marketing} /> },
                        { path: 'portfolio', element: <LoadComponent component={Portfolio} /> },
                    ],
                },                
                {
                    path: 'investments',
                    children: [
                        { path: '', element: <LoadComponent component={Investment} /> },
                    ],
                },
                {
                    path: 'pages',
                    children: [
                        {
                            path: 'blog',
                            children: [
                                // { path: '', element: <LoadComponent component={Blog} /> },
                                { path: 'post2', element: <LoadComponent component={BlogPost} /> },
                                { path: ':id', element: <LoadComponent component={NewPost} /> },
                                // { path: 'post3', element: <LoadComponent component={BlogPost} /> },
                            ],
                        },                        
                        {
                            path: 'investment',
                            children: [
                                { path: '', element: <LoadComponent component={Investment} /> },
                                { path: ':id', element: <LoadComponent component={InvestmentPost} /> },
                                // { path: 'post2', element: <LoadComponent component={InvestmentPost} /> },
                                // { path: 'post3', element: <LoadComponent component={InvestmentPost} /> },
                            ],
                        },
                        { path: 'about', element: <LoadComponent component={About} /> },
                        { path: 'contact', element: <LoadComponent component={Contact} /> },
                        { path: 'privacy', element: <LoadComponent component={Policy} /> },
                        { path: 'career', element: <LoadComponent component={Career} /> },
                        { path: 'pricing', element: <LoadComponent component={Pricing} /> },
                        {
                            path: 'portfolio',
                            children: [
                                { path: 'grid', element: <LoadComponent component={PortfolioGrid} /> },
                                { path: 'masonry', element: <LoadComponent component={PortfolioMasonry} /> },
                                { path: 'item', element: <LoadComponent component={PortfolioItem} /> },
                            ],
                        },
                        { path: 'help', element: <LoadComponent component={HelpDesk} /> },
                    ]
                },
            ],
        },
        {
            // protected routes
            // path: '/',
            // element: <PrivateRoute roles={'Admin'} />,
            // children: [
            //     {
            //         path: 'pages',
            //         children: [
            //             {
            //                 path: 'account',
            //                 children: [
            //                     { path: 'dashboard', element: <LoadComponent component={Dashboard} /> },
            //                     { path: 'settings', element: <LoadComponent component={Settings} /> },
            //                     { path: 'add-post', element: <LoadComponent component={AddPost} /> },
            //                 ],
            //             },
            //         ],
            //     },
            // ],
        },
    ]);
};

export default AllRoutes;
